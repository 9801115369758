import Layout from '@/layouts/index.vue';

export default [
  {
    path: '/result',
    name: 'result',
    component: () => import('@/layouts/index.vue'),
    meta: { title: '响应结果', icon: 'check-circle', hidden: true },
    children: [
      {
        path: 'success',
        name: 'ResultSuccess',
        component: () => import('@/pages/result/success/index.vue'),
        meta: { title: '成功页' },
      },
      {
        path: 'fail',
        name: 'ResultFail',
        component: () => import('@/pages/result/fail/index.vue'),
        meta: { title: '失败页' },
      },
      {
        path: 'network-error',
        name: 'ResultNetworkError',
        component: () => import('@/pages/result/network-error/index.vue'),
        meta: { title: '网络异常' },
      },
      {
        path: 'browser-incompatible',
        name: 'ResultBrowserIncompatible',
        component: () => import('@/pages/result/browser-incompatible/index.vue'),
        meta: { title: '浏览器不兼容页' },
      },
      {
        path: 'maintenance',
        name: 'ResultMaintenance',
        component: () => import('@/pages/result/maintenance/index.vue'),
        meta: { title: '系统维护页' },
      },
    ],
  },
  {
    path: '/403',
    name: 'Page403',
    component: () => import('@/layouts/index.vue'),
    meta: { title: '响应结果', icon: 'check-circle', hidden: true },
    children: [
      {
        path: '',
        name: 'Result403',
        component: () => import('@/pages/result/403/index.vue'),
        meta: { title: '无权限' },
      },
    ],
  },
  {
    path: '/404',
    name: 'Page404',
    component: () => import('@/layouts/index.vue'),
    meta: { title: '响应结果', icon: 'check-circle', hidden: true },
    children: [
      {
        path: '',
        name: 'Result404',
        component: () => import('@/pages/result/404/index.vue'),
        meta: { title: '访问页面不存在页' },
      },
    ],
  },
  {
    path: '/500',
    name: 'Page500',
    component: () => import('@/layouts/index.vue'),
    meta: { title: '响应结果', icon: 'check-circle', hidden: true },
    children: [
      {
        path: '',
        name: 'Result500',
        component: () => import('@/pages/result/500/index.vue'),
        meta: { title: '服务器出错页' },
      },
    ],
  },
];
