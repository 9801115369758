export default [
  {
    path: '/',
    component: () => import('@/layouts/index.vue'),
    name: 'Dashboard',
    meta: {
      title: '运营状况',
      icon: 'chart',
      single: true,
      expanded: false,
    },
    children: [
      {
        path: '',
        name: 'DashboardIndex',
        component: () => import('@/pages/dashboard/index.vue'),
        meta: { title: '数据看板' },
      },
    ],
  },
  {
    path: '/user',
    name: 'User',
    component: () => import('@/layouts/index.vue'),
    meta: { title: '用户管理', icon: 'user' },
    children: [
      {
        path: 'list',
        name: 'UserList',
        component: () => import('@/pages/user/list/index.vue'),
        meta: { title: '用户列表', keepAlive: true },
      },
      {
        path: ':id',
        name: 'UserDetail',
        component: () => import('@/pages/user/detail/index.vue'),
        meta: { title: '用户详情', hidden: true, keepAlive: true },
      },
    ],
  },
  {
    path: '/order',
    name: 'Order',
    component: () => import('@/layouts/index.vue'),
    meta: { title: '订单管理', icon: 'view-module' },
    children: [
      {
        path: '',
        name: 'OrderListPage',
        component: () => import('@/pages/order/list/index.vue'),
        meta: { title: '订单列表', keepAlive: true },
      },
    ],
  },
  {
    path: '/vip',
    name: 'VipCombo',
    component: () => import('@/layouts/index.vue'),
    meta: { title: '会员套餐', icon: 'icon-vip' },
    children: [
      {
        path: '',
        name: 'VipComboList',
        component: () => import('@/pages/vip/index.vue'),
        meta: { title: '套餐管理', keepAlive: true },
      },
    ],
  },
  {
    path: '/fee',
    name: 'FeePage',
    component: () => import('@/layouts/index.vue'),
    meta: { title: '佣金管理', icon: 'icon-fee2' },
    children: [
      {
        path: '/list',
        name: 'FeeFlowPage',
        keepAlive: true,
        component: () => import('@/pages/commission/flow/index.vue'),
        meta: { title: '佣金流水', keepAlive: true },
      },
      {
        path: '/settle',
        name: 'commissionSetting',
        component: () => import('@/pages/commission/setting/index.vue'),
        meta: { title: '结算设置' },
      },
    ],
  },
  {
    path: '/chatgpt',
    name: 'Chatgpt',
    component: () => import('@/layouts/index.vue'),
    meta: { title: 'ChatGPT', icon: 'icon-openai' },
    children: [
      {
        path: '',
        name: 'usage',
        component: () => import('@/pages/chatgpt/usage/index.vue'),
        meta: { title: '接口用量' },
      },
      {
        path: 'keywords',
        name: 'keywordListPage',
        component: () => import('@/pages/chatgpt/keyword/index.vue'),
        meta: { title: '敏感词汇' },
      },
      {
        path: 'tips',
        name: 'freetipPage',
        component: () => import('@/pages/chatgpt/freetip/index.vue'),
        meta: { title: '白嫖提示语' },
      },
      {
        path: 'prompt',
        name: 'promptStorePage',
        component: () => import('@/pages/chatgpt/prompt/index.vue'),
        meta: { title: '提示词商店' },
      },
    ],
  },
];
